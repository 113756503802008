.rima-header {
  background: #a7bbc7;
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 1px solid #dce9f0;
  }

  .btn-warning {
    background: #ff6b07;
    padding: 0.5rem 4rem;
    border-radius: 18px;
    color: white;
    text-decoration: none;
  }

  h1 {
    font-size: 2rem;
    color: white;
    margin-top: 4rem;
    font-weight: bold;
  }

  h4 {
    color: white;
    font-size: 1rem;
    margin: 1rem 0;
    padding-right: 20vw;
    line-height: 24px;
  }

  .rima-img {
    max-height: 70vh;
  }

  @media only screen and (max-width: 600px) {
    h1 {
      margin-top: 0;
    }
    h4 {
      padding-right: 0;
    }
  }
}

.rima-space {
  p {
    padding: 4rem;
  }
}

.rima-header .description {
  text-align: left;
  padding: 2rem;
}
