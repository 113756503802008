.home-steps {
  h2 {
    color: #89007a;
    font-weight: bold;
    padding: 1rem;
    font-size: 2rem;
    margin-top: 4rem;
  }

  .conectados-color {
    max-width: 60vw;
    margin-bottom: 2rem;
  }

  .btn-primary {
    background: #89007a;
    border-radius: 22px;
    border: none;
    margin-top: 1rem;
  }
  .vertical-timeline-element-content {
    background: #f9f7f7;
  }
}
