.navbar-light .navbar-nav .nav-link {
  color: white;
  padding: 0.5rem 2rem;
  text-align: right;
  border-bottom: 1px solid #a71a93;
}

.navbar-toggler-icon {
  background-image: url('/menu.svg') !important;
}

.navbar-light .navbar-toggler {
  color: transparent;
}

.r-menu {
  font-weight: bold;
}
.header-container {
  .navbar {
    height: 10vh;
    .left-side {
      a {
        /* margin-right: 4rem; */
      }
      /*   a {
        width: 100%;

        .navbar-brand {
          text-align: center;
          margin-right: 0;
          img {
          }
        }
      } */
    }
  }

  @media only screen and (max-width: 767px) {
    .shadow-menu {
      background: rgb(244 239 239 / 90%);
    }
    .navbar-light .navbar-nav .nav-link {
      color: #a71a93;
    }
  }
}
