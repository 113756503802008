.home-doctor {
  margin-top: 4rem;
  .doctor-icon {
    max-height: 60vh;
    img {
      z-index: 99;
    }
  }
  .text-box {
    color: white;
    font-size: 1rem;
    text-align: left;
    padding: 2rem 1.5rem;
    background: linear-gradient(
      0deg,
      #7e77f9 11%,
      #7986fa 41%,
      #6cacfd 98%,
      #6cadfd 100%
    );
    border-radius: 24px;
    /*   transform: rotate(180deg); */
    min-height: 22vh;

    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
}

@media only screen and (max-width: 699px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
    text-align: center;
  }
}
