.politics {
  h3, h1 {
    margin: 1rem;
  }
  h3 {
    color: #9d0187;
    font-weight: bold;
  }

  section {
    padding-top: 1rem;
  }
}