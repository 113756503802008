.home-header {
  /*  padding-top: 10vh; */
  color: white;
  min-height: 50vh;
  margin-bottom: 5vh;
  background: linear-gradient(
    0deg,
    #7e77f9 11%,
    #7986fa 41%,
    #6cacfd 98%,
    #6cadfd 100%
  );

  .bg {
    animation-name: MOVE-BG;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 1px solid #709ffc;
  }

  .welcome {
    padding-top: 2rem;

    img {
      margin-bottom: 1rem;
    }
    .conectados {
      margin-top: 5vh;
      max-width: 60vw;
      max-height: 3vh;
      height: 2rem !important;
    }
    h1 {
      /*  margin-top: 16px; */
      font-size: 2.5rem;
      font-weight: bold;
    }

    h2 {
      color: #00ffff;
      font-weight: 100;
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }

  .phone {
    position: absolute;
    /* height: 60vh; */
    /* */
    max-height: 60vh;
  }
}

@keyframes MOVE-BG {
  from {
    background-position: 0px 150px;
  }
  to {
    background-position: 250px 450px;
  }
}

.text-left {
  text-align: left;
}
.join-us {
  background: #89007a;
  padding: 0.5rem 1rem;
  color: white;
  text-decoration: none;
  border-radius: 16px;
  margin: 1rem 0;
}

.link {
  color: #89007a;
  text-decoration: none;
}
.bago-white {
  position: absolute;
  width: 160px;
  left: 60px;
  top: 20px;
}

/* .bg-image {
    width: 100%;
    height:100%;
    background: url('../../../public/background.png') repeat-y;
} */

.welcome-icon {
  /*  width: 60vw; */
  /*   margin: 2rem 0; */
}

.steps {
  margin-top: 4rem;
  height: auto;
  width: 30vw;
}

@media only screen and (max-width: 600px) {
  .home-header .phone {
    position: relative;
    max-height: 60vh;
    left: 0;
  }
  .text-left {
    text-align: center;
  }
  .bago-white {
    position: absolute;
    width: 100px;
    left: 40px;
    top: 20px;
  }

  .home-welcome {
    /*  padding: 0 !important; */
  }
  .welcome-icon {
    width: 100vw;
  }

  .steps {
    margin-top: 2rem;
    height: auto;
    width: 80vw;
  }
}

.offline-phone {
  height: 60vh;
}

.home-header .conectados {
  height: 2.2rem;
}

.home-welcome {
  /* padding: 2rem 1rem; */
}
.home-welcome .text-box {
  padding: 3rem;
  background: linear-gradient(
    0deg,
    #7e77f9 11%,
    #7986fa 41%,
    #6cacfd 98%,
    #6cadfd 100%
  );
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  color: white;
  font-size: 0.9rem;
  text-align: left;
}

.home-features {
  margin-top: 4rem;
  padding: 2rem 0;
}
.feature-box {
  margin: 1rem 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    margin-top: 2rem;
    font-weight: bold;
  }
}
.feature-box .icon {
  background: linear-gradient(90deg, #7e77f9 6%, #6cadfd 100.01%);
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 10px;

  img {
    height: 30px;
  }
}
.feature-box p {
  text-align: center;
  padding: 0rem 2rem;
  margin: 0;
  font-size: 0.8rem;
}

.home-steps {
  background: white;
}

.home-steps .title {
  font-size: 1.6rem;
}

.vertical-timeline {
  --line-color: linear-gradient(0deg, #6cadfd 0%, #7e77f9 94%) !important;
}

.vertical-timeline-element-content {
  background: #f9f7f7;
  border-radius: 16px;
}

.vertical-timeline-element-icon {
  /* background: #00ffff !important; */
  background: linear-gradient(90deg, #7e77f9 6%, #6cadfd 100.01%) !important;
}

.home-offline {
  /* border: 1px solid red; */
  padding: 4rem 0;

  .content {
    margin-top: 2rem;
  }
}

.home-offline h4,
.home-offline p {
  text-align: left;
  color: white;
}
.home-offline h4 {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.home-offline {
  padding: 4rem 2rem;
  background: linear-gradient(
    104.04deg,
    #7e77f9 11%,
    #7986fa 41%,
    #6cacfd 98%,
    #6cadfd 100%
  );
}

.vertical-timeline-element-content-arrow {
  border: none;
}

.vertical-timeline-element-content {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.vertical-timeline-element-icon {
  top: 0;
  /* margin-left: -10px !important;
    width: 20px !important;
    height: 20px !important; */
}
