$mainColor: #9d0187;

.follow {
  text-align: center;
  p {
    /*  margin-right: 2rem;
    margin-left: 2rem; */
    color: $mainColor;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
  }

  a {
    /* margin: 1rem; */
    width: 40px;
    height: 40px;
    img {
      /*  width: 40px;
      height: 40px; */
    }
  }
}

.footer .terms {
  padding: 4rem;
  text-align: left;
}

.footer .follow {
  padding: 4rem;
}
.footer .copy {
  padding: 1rem;
  /*     border: 1px solid green */
}

.footer .boreal {
  text-align: right;
}
.boreal-icon {
  height: 15px;
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  padding-inline-start: 0;
}
li {
  margin-bottom: 0.5rem;
}
.footer a {
  text-decoration: none;
  color: black;
}

.link-bago {
  color: #89007a !important;
}

.bago-magenta {
  width: 200px;
  margin-bottom: 1rem;
}
