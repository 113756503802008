$mainColor: #9d0187;
.vademecum {
  .resume {
    background-color: $mainColor;
    animation-name: MOVE-BG;
    animation-duration: 60s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    .description {
      margin-top: 4rem;
    }

    h1 {
      text-align: left;
      color: white;
      font-size: 2.5rem;
      font-weight: bold;
      margin: 1rem 0;
    }

    h4 {
      font-family: 'Kanit', sans-serif;
      text-align: left;
      font-size: 1.2rem;
      color: #00ffff;
      padding-right: 20vw;
      margin: 2rem 0;
      padding-left: 0;
      padding-right: 10vw;
      line-height: 24px;
    }

    p {
      margin-top: 4rem;
      color: white;
      text-align: left;
    }

    .screen {
      text-align: center;
      img {
        margin-top: 1rem;
        margin-bottom: -3px;
        max-width: 80vw;
        max-height: 60vh;
      }
    }
  }
  .detail {
    padding: 6rem 4rem;
    p {
      font-size: 1rem;
    }
  }
}

@keyframes MOVE-BG {
  from {
    background-position: 0px 150px;
  }
  to {
    background-position: 250px 450px;
  }
}
/* .vademecum-header {
    height: 98vh;
    background: #9D0187;
   
    display: flex;
  align-items: flex-start;
} */

/* 
.vademecum-header .screen {
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* .vademecum-header .screen img {
  height: 40vh;
    bottom: -2px;
    position: absolute; 
} */

.vademecum-header .description {
  /* margin: 0 2rem; */
  /*   padding: 0 ;
    width: 50vw;
    border: 1px solid green;
    height: 80vh */
}
/* 
.vademecum-header .description p{
    color: white;
    text-align: left;
}

.vademecum-header .description a{
    color: white;
    text-decoration: none;
   
}
.vademecum-header .description a img {
    width: 140px;
   
} */

.vademecum-header .description .text {
  /*  padding-top: 25vh */
}
/* 
.vademecum-header .description h1 {
  text-align: left;
    color: white;
    font-size: 2rem;
}

.vademecum-header .description h4 {
    text-align: left;
    font-size: 1.1rem;
    color: #00FFFF;
    padding-right: 20vw;
} */
