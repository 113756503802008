.navbar-light .navbar-nav .nav-link {
  color: white;
  padding: 0.5rem 2rem;
  text-align: right;
  border-bottom: 1px solid #a71a93;
}

.store-buttons {
  p {
    padding: 0;
    margin: 0;
  }
  .store-links {
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    text-align: left;
  }
}
